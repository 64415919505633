$shadow-color-black: darken($better-black, 5%);  // Slightly darkened color for the shadow

.thanks-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // This will center the container vertically in the viewport
    background-color: $better-white;

    .thanks-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        width: 600px; // Match width with .role-wrapper
        height: 400px; // Match height with .role-wrapper
        background-color: $better-white;
        border-radius: $border-radius;

        h2 {
            font-family: $font;
            margin-bottom: 2rem;
            font-size: large;
            color: black; 
        }

        .thanks-button {
            background-color: $better-white;
            width: 150px; // Match width with .role-buttons-container .btn
            height: 40px; // Match height with .role-buttons-container .btn
            margin: 1rem 0;
            border: none;
            border-radius: 5px;
            font-family: $font;
            cursor: pointer;
            transition: opacity 0.3s;
            box-shadow: 0 0px 10px 1px rgba($shadow-color-black, 0.1);  // Soft, diffuse shadow
            color: black;

            &:hover {
                box-shadow: 0 2px 15px rgba($shadow-color-black, 0.3);
            }
        }
    }
}