$better-white: $better-white;
$creator-pink: $creator-pink;
$shadow-color-creator: darken($creator-pink, 5%);  
$font: $font;

.creator-register-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $better-white;

    .creator-register-form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        width: 550px;
        background-color: $better-white;
        border-radius: 5px; 

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            input[type="text"], input[type="email"], input[type="password"] {
                width: 100%;
                padding: 0.75rem;
                border: none;
                border-radius: 5px;
                background-color: lighten($better-white, 5%);
                font-family: $font;
                box-shadow: 0 0px 10px 1px rgba($shadow-color-creator, 0.1);  // Soft, diffuse shadow
                
                &:focus {
                    outline: none;
                    box-shadow: 0 2px 15px rgba($shadow-color-creator, 0.3);
                }
            }

            label {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                cursor: pointer;

                img {
                    width: 24px;
                    height: 24px;
                }

                span {
                    font-family: $font;
                    color: $better-black;
                }
            }

            button {
                width: 100%;
                padding: 0.75rem;
                border: none;
                border-radius: 5px;
                font-family: $font;
                cursor: pointer;
                transition: opacity 0.3s;
                background-color: $better-white;
                color: $better-black;
                box-shadow: 0 0px 10px 1px rgba($shadow-color-creator, 0.1);  // Soft, diffuse shadow

                /*&:hover {
                    box-shadow: 0 2px 15px rgba($shadow-color-creator, 0.3);
                }*/

                &:active {
                    opacity: 0.6;
                }
            }

            .creator-register-signup {
              background-color: transparent;
              box-shadow: none;
              color: $creator-pink;
              padding: 0.75rem 2.5rem 0.75rem 0.75rem; 
              position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    right: 10px;
                    top: 35%;
                    transform: translateY(-50%);
                    border: solid $creator-pink;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 5px;
                    transform: rotate(-45deg);
                }

              }

          .creator-register-google {
              background-image: url('../../img/google.png'); // Replace with the path to your Google logo
              background-repeat: no-repeat;
              background-size: 20px;
              background-position: center;
              box-shadow: 0 0px 10px 1px rgba($shadow-color-creator, 0.1);  // Soft, diffuse shadow
              color: transparent;
              text-align: center;
              margin-top: 1rem; // Spacing for the divider
              padding: 0.75rem 0;

          }

          // Divider before Google sign-up
          .creator-register-signup + .creator-register-google {
              margin-top: 1.5rem; // Adjust to your preferred spacing

              &:before {
                  content: "";
                  display: block;
                  height: 0.75px;
                  background-color: $shadow-color-creator;
                  width: 100%;
                  position: relative;
                  top: -1.5rem;
              }
          }
        }

        span, p {
            font-family: $font;
            color: $better-black;
            margin-top: 1rem;
        }

        a {
          color: $creator-pink;
          text-decoration: none;
          position: absolute; // Absolute positioning
          top: 15px; // Move it to the top with some padding
          right: 15px; // Move it to the right with some padding

          &:hover {
              text-decoration: underline;
          }
      }
    }
}
